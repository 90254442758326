import './App.css';
import OneSignal from 'react-onesignal';
import {useState} from 'react';
import React, { useEffect } from 'react';



const App = () => {
  const [count, setCount] = useState(0);

  const handleClick = event => {
    setCount(current => current + 1);
  };
    // OneSignal initialization code
  


  // useEffect(() => {
  //   OneSignal.init({
  //     allowLocalhostAsSecureOrigin: true,
  //     appId: "1a8b31df-3710-412a-b5d3-cab6b3e646ce"
  //   });

    
  // }, []);

  useEffect(() => {
    (async () => {
      try{
        await OneSignal.init({
          appId: "1a8b31df-3710-412a-b5d3-cab6b3e646ce",
          notifyButton: {
            enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
        })
  
        await OneSignal.Slidedown.promptPush();
      } catch (e) {
      console.log(`Swallowed error: ${e}`)
      }
    })()
  }, [])




  return (
    <div>
      <h2>Count: {count}</h2>
      <button onClick={handleClick}>Increment</button>
    </div>
  );
};


export default App;
